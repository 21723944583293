<!--
 * @Author: SongYijie
 * @Date: 2020-03-26 17:41:34
 * @LastEditors: SongYijie
-->
<template>
  <div class="main has-header bg-gray">
    <div class="select-area">
      <div class="item-wrap" @click="handleGotoAuth">
        <div>
          <p class="title">居民身份证认证</p>
          <p class="info">我持有中华人民共和国居民身份证</p>
        </div>
        <img class="icon" src="@assets/images/user/mainland.png" alt="mainland" />
      </div>
      <div class="item-wrap" @click="handleGotoApplyUser">
        <div>
          <p class="title">其他身份证认证</p>
          <p class="info">我持有其他身份证（港澳居民来往内地通行证，台湾居民来往大陆通行证）</p>
        </div>
        <img class="icon" src="@assets/images/user/others.png" alt="others" />
      </div>
      <span class="description" @click="handleGotoDescription">证件说明</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    handleGotoDescription() {
      this.$router.push('/user/description');
    },
    handleGotoAuth() {
      this.$router.push('/user/authentication');
    },
    handleGotoApplyUser() {
      this.$router.push('/user/applyUser');
    }
  }
};
</script>
<style lang='less' scoped>
p {
  margin: 0;
}
.select-area {
  width: 100%;
}
.item-wrap {
  width: 89.3333vw;
  height: 36vw;
  border-radius: 3.2vw;
  background-color: #ffffff;
  margin: 6.6667vw auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.title {
  width: 46.6667vw;
  font-size: 5.6vw;
  font-weight: bolder;
  color: black;
  margin-bottom: 1.6vw;
}
.info {
  font-size: 2.9333vw;
  width: 46.6667vw;
}
.icon {
  width: 26.6667vw;
  height: 26.6667vw;
}
.description {
  font-size: 2.9333vw;
  position: fixed;
  left: 50%;
  bottom: 13.3333vw;
  transform: translate(-50%);
  color: rgb(63, 51, 129);
}
</style>
